<template>
  <dx-util-popup
    ref="wfsTemplateDetailsPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="wfsTemplateDetailsPopupShown"
    @hidden="wfsTemplateDetailsPopupHidden"
  >
  <dx-util-scroll-view height="90%" width="100%">
    <div>
      <dx-util-tabs :selected-index="0" @item-click="filterShippingMethod">
        <dx-item text="Value" />
        <dx-item text="Standard" />
        <dx-item text="2 Day Shipping" />
        <dx-item text="Freight" />
      </dx-util-tabs>
      <div class="p-1">
        <div class="">
          <span class="mr-1">Status:</span>
          <span class="badge bg-success">
            {{ selectedShippingMethod.status }}
          </span>
          <div v-for="(config, index) in selectedShippingMethod.configurations" :key="index" class="my-1">
            <div class="row align-items-end mt-1">
              <div v-for="region in config.regions" :key="region.regionCode" class="text-center mx-2 col-2">
                <small class="text-muted d-block">
                  Region Name
                </small>
                <div class="badge badge-primary mx-1">
                  <h5 class="m-0 p-0">
                    {{ region.regionName }}
                  </h5>
                </div>
            </div>
            <div class="mx-2">
              <small class="text-muted d-block">Address Type</small>
              <div class="badge badge-primary">
                <h5 class="m-0 p-0">
                  {{ config.addressTypes.toString() }}
                </h5>
              </div>
            </div>
            <div class="mx-2">
              <small class="text-muted d-block">Transit Time</small>
              <div class="badge badge-warning">
                <h5 class="m-0 p-0">
                  {{ config.transitTime }} days
                </h5>
              </div>
            </div>
            <div>
              <div class="text-center">
                Per Shipping Charge
              </div>
              <div v-if="config.perShippingCharge" class="d-flex">
                <div v-for="(item, k) in config.perShippingCharge" :key="k" class="d-flex mx-1">
                  <div v-if="item" class="text-center">
                    <small class="text-muted d-block">{{ camelCaseToTitleCase(k) }}</small>
                    <div class="badge badge-success">
                      <h5 v-if="k === 'unitOfMeasure'" class="m-0 p-0">
                        {{ item }}
                      </h5>
                      <h5 v-if="k === 'shippingAndHandling'" class="m-0 p-0">
                          {{ item.amount }} {{ item.currency }}
                      </h5>
                      <h5 v-if="k === 'chargePerWeight'" class="m-0 p-0">
                          {{ item.amount }} {{ item.currency }}
                      </h5>
                      <h5 v-if="k === 'chargePerItem' && item !== null" class="m-0 p-0">
                          {{ item.amount }} {{ item.currency }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div>
                Tiered Shipping Charges
              </div>
              <div class="text-primary">
                <h5 v-for="charge in config.tieredShippingCharges" :key="charge">
                  {{ charge }}
                </h5>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <wfs-region-map v-if="selectedShippingMethod.shipMethod === 'STANDARD'" :region-data="config.regions" />
          </div>
        </div>
        </div>
      </div>
    </div>
  </dx-util-scroll-view>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import { DxItem } from 'devextreme-vue/tabs'
import useWfsTemplates from '../useWfsTemplates.js'
import WfsRegionMap from './WfsRegionMap.vue'

export default {
  components: {
    'wfs-region-map': WfsRegionMap,
    'dx-item': DxItem,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popupTitle: '',
      shipMethod: 'VALUE',
      selectedShippingMethod: {},
      shipmentMethods: {
        0: 'VALUE',
        1: 'STANDARD',
        2: '2_DAY_SHIPPING',
        3: 'FREIGHT',
      },
      shipmentMethodsObj: {},
    }
  },
  setup() {
    const {
      templateDetails,
    } = useWfsTemplates()
    return {
      templateDetails,
    }
  },
  computed: {
    wfsTemplateDetailsPopup() {
      return this.$refs.wfsTemplateDetailsPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.wfsTemplateDetailsPopup.show()
        this.templateDetails.shippingMethods.forEach((method, index) => {
          this.shipmentMethodsObj[index] = method
        })
      },
    },
  },
  methods: {
    wfsTemplateDetailsPopupShown() {
      this.popupTitle = 'Title'
    },
    wfsTemplateDetailsPopupHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.wfsTemplateDetailsPopup.hide()
    },
    handleSubmit() {
      this.$emit('emit-form-saved')
      this.closePopup()
    },
    filterShippingMethod(e) {
      const selectedMethod = this.shipmentMethods[e.itemIndex]
      this.selectedShippingMethod = this.templateDetails.shippingMethods.filter(el => el.shipMethod === selectedMethod)[0] ?? {}
    },
    camelCaseToTitleCase(str) {
      return str.replace(/([A-Z])/g, ' $1').trim().split(' ')
        .map(word => {
          if (/.*[A-Z].*/.test(word.slice(1))) {
            return word.toUpperCase()
          }
          return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
