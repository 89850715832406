export const regions = [
  {
    region: 'South Region States',
    states: [
      {
        state: 'AL',
        sub_regions: [
          {
            sub_region: 'AL_NORTH',
            sub_region_id: 'AL1',
          },
          {
            sub_region: 'AL_SOUTH',
            sub_region_id: 'AL2',
          },
        ],
      },
      {
        state: 'AR',
        sub_regions: [
          {
            sub_region: 'AR_EAST',
            sub_region_id: 'AR1',
          },
          {
            sub_region: 'AR_WEST',
            sub_region_id: 'AR2',
          },
        ],
      },
      {
        state: 'DC',
        sub_regions: [
          {
            sub_region: 'DC',
            sub_region_id: 'DC',
          },
        ],
      },
      {
        state: 'DE',
        sub_regions: [
          {
            sub_region: 'DE',
            sub_region_id: 'DE',
          },
        ],
      },
      {
        state: 'FL',
        sub_regions: [
          {
            sub_region: 'FL_CENTRAL_EAST',
            sub_region_id: 'FL1',
          },
          {
            sub_region: 'FL_CENTRAL_WEST',
            sub_region_id: 'FL2',
          },
          {
            sub_region: 'FL_MIAMI',
            sub_region_id: 'FL3',
          },
          {
            sub_region: 'FL_NORTH',
            sub_region_id: 'FL4',
          },
          {
            sub_region: 'FL_SOUTH_EAST',
            sub_region_id: 'FL5',
          },
          {
            sub_region: 'FL_SOUTH_WEST',
            sub_region_id: 'FL6',
          },
        ],
      },
      {
        state: 'GA',
        sub_regions: [
          {
            sub_region: 'GA_ATLANTA',
            sub_region_id: 'GA1',
          },
          {
            sub_region: 'GA_CENTRAL',
            sub_region_id: 'GA2',
          },
          {
            sub_region: 'GA_NORTH_EAST',
            sub_region_id: 'GA3',
          },
          {
            sub_region: 'GA_NORTH_WEST',
            sub_region_id: 'GA4',
          },
          {
            sub_region: 'GA_SOUTH',
            sub_region_id: 'GA5',
          },
        ],
      },
      {
        state: 'KY',
        sub_regions: [
          {
            sub_region: 'KY_EAST',
            sub_region_id: 'KY1',
          },
          {
            sub_region: 'KY_WEST',
            sub_region_id: 'KY2',
          },
        ],
      },
      {
        state: 'LA',
        sub_regions: [
          {
            sub_region: 'LA_NORTH',
            sub_region_id: 'LA1',
          },
          {
            sub_region: 'LA_SOUTH',
            sub_region_id: 'LA2',
          },
        ],
      },
      {
        state: 'MS',
        sub_regions: [
          {
            sub_region: 'MS_NORTH',
            sub_region_id: 'MS1',
          },
          {
            sub_region: 'MS_SOUTH',
            sub_region_id: 'MS2',
          },
        ],
      },
    ],
  },
  {
    region: 'North East Region States',
    states: [
      {
        state: 'CT',
        sub_regions: [
          {
            sub_region: 'CT_REST_ALL',
            sub_region_id: 'CT1',
          },
          {
            sub_region: 'CT_SOUTH_WEST',
            sub_region_id: 'CT2',
          },
        ],
      },
      {
        state: 'NJ',
        sub_regions: [
          {
            sub_region: 'NJ_CENTRAL',
            sub_region_id: 'NJ1',
          },
          {
            sub_region: 'NJ_NORTH',
            sub_region_id: 'NJ2',
          },
          {
            sub_region: 'NJ_SOUTH',
            sub_region_id: 'NJ3',
          },
          {
            sub_region: 'NJ_KEARNY',
            sub_region_id: 'NJ4',
          },
        ],
      },
      {
        state: 'NH',
        sub_regions: [
          {
            sub_region: 'NH',
            sub_region_id: 'NH',
          },
        ],
      },
      {
        state: 'NY',
        sub_regions: [
          {
            sub_region: 'NY_BROOKLYN',
            sub_region_id: 'NY1',
          },
          {
            sub_region: 'NY_CENTRAL',
            sub_region_id: 'NY2',
          },
          {
            sub_region: 'NY_NORTH_CENTRAL',
            sub_region_id: 'NY3',
          },
          {
            sub_region: 'NY_NORTH_WEST',
            sub_region_id: 'NY4',
          },
          {
            sub_region: 'NY_SOUTH',
            sub_region_id: 'NY5',
          },
        ],
      },
      {
        state: 'PA',
        sub_regions: [
          {
            sub_region: 'PA_CENTRAL',
            sub_region_id: 'PA1',
          },
          {
            sub_region: 'PA_CENTRAL_NORTH',
            sub_region_id: 'PA2',
          },
          {
            sub_region: 'PA_NORTH_EAST',
            sub_region_id: 'PA3',
          },
          {
            sub_region: 'PA_SOUTH',
            sub_region_id: 'PA4',
          },
          {
            sub_region: 'PA_WEST',
            sub_region_id: 'PA5',
          },
        ],
      },
      {
        state: 'RI',
        sub_regions: [
          {
            sub_region: 'RI',
            sub_region_id: 'RI',
          },
        ],
      },
      {
        state: 'VT',
        sub_regions: [
          {
            sub_region: 'VT',
            sub_region_id: 'VT',
          },
        ],
      },
    ],
  },
  {
    region: 'Mid West Region States',
    states: [
      {
        state: 'IA',
        sub_regions: [
          {
            sub_region: 'IA_CENTRAL',
            sub_region_id: 'IA1',
          },
          {
            sub_region: 'IA_REST',
            sub_region_id: 'IA2',
          },
        ],
      },
      {
        state: 'IL',
        sub_regions: [
          {
            sub_region: 'IL_CHICAGO',
            sub_region_id: 'IL1',
          },
          {
            sub_region: 'IL_NORTH_EAST',
            sub_region_id: 'IL2',
          },
          {
            sub_region: 'IL_NORTH_WEST',
            sub_region_id: 'IL3',
          },
          {
            sub_region: 'IL_SOUTHEAST',
            sub_region_id: 'IL4',
          },
          {
            sub_region: 'IL_SOUTHWEST',
            sub_region_id: 'IL5',
          },
        ],
      },
      {
        state: 'IN',
        sub_regions: [
          {
            sub_region: 'IN_CENTRAL_AND_EAST',
            sub_region_id: 'IN1',
          },
          {
            sub_region: 'IN_NORTH',
            sub_region_id: 'IN2',
          },
          {
            sub_region: 'IN_SOUTH',
            sub_region_id: 'IN3',
          },
          {
            sub_region: 'IN_WEST',
            sub_region_id: 'IN4',
          },
        ],
      },
      {
        state: 'KS',
        sub_regions: [
          {
            sub_region: 'KS_EAST',
            sub_region_id: 'KS1',
          },
          {
            sub_region: 'KS_WEST',
            sub_region_id: 'KS2',
          },
        ],
      },
      {
        state: 'MI',
        sub_regions: [
          {
            sub_region: 'MI_CENTRAL',
            sub_region_id: 'MI1',
          },
          {
            sub_region: 'MI_NORTH',
            sub_region_id: 'MI2',
          },
          {
            sub_region: 'MI_SOUTH_EAST',
            sub_region_id: 'MI3',
          },
        ],
      },
      {
        state: 'MN',
        sub_regions: [
          {
            sub_region: 'MN_CENTRAL_EAST',
            sub_region_id: 'MN1',
          },
          {
            sub_region: 'MN_REST_ALL',
            sub_region_id: 'MN2',
          },
        ],
      },
      {
        state: 'MO',
        sub_regions: [
          {
            sub_region: 'MO_EAST',
            sub_region_id: 'MO1',
          },
          {
            sub_region: 'MO_SOUTH',
            sub_region_id: 'MO2',
          },
          {
            sub_region: 'MO_WEST',
            sub_region_id: 'MO3',
          },
        ],
      },
      {
        state: 'ND',
        sub_regions: [
          {
            sub_region: 'ND',
            sub_region_id: 'ND',
          },
        ],
      },
      {
        state: 'NE',
        sub_regions: [
          {
            sub_region: 'NE',
            sub_region_id: 'NE',
          },
        ],
      },
      {
        state: 'OH',
        sub_regions: [
          {
            sub_region: 'OH_CENTRAL',
            sub_region_id: 'OH1',
          },
          {
            sub_region: 'OH_NORTH',
            sub_region_id: 'OH2',
          },
          {
            sub_region: 'OH_SOUTH_EAST',
            sub_region_id: 'OH3',
          },
          {
            sub_region: 'OH_WEST',
            sub_region_id: 'OH4',
          },
        ],
      },
      {
        state: 'SD',
        sub_regions: [
          {
            sub_region: 'SD_EAST',
            sub_region_id: 'SD1',
          },
          {
            sub_region: 'SD_WEST',
            sub_region_id: 'SD2',
          },
        ],
      },
      {
        state: 'WI',
        sub_regions: [
          {
            sub_region: 'WI_EAST',
            sub_region_id: 'WI1',
          },
          {
            sub_region: 'WI_WEST',
            sub_region_id: 'WI2',
          },
        ],
      },
      {
        state: 'WV',
        sub_regions: [
          {
            sub_region: 'WV_EAST',
            sub_region_id: 'WV1',
          },
          {
            sub_region: 'WV_WEST',
            sub_region_id: 'WV2',
          },
        ],
      },
    ],
  },
  {
    region: 'North East Region',
    states: [
      {
        state: 'MA',
        sub_regions: [
          {
            sub_region: 'MA_EAST',
            sub_region_id: 'MA1',
          },
          {
            sub_region: 'MA_WEST',
            sub_region_id: 'MA2',
          },
        ],
      },
      {
        state: 'MD',
        sub_regions: [
          {
            sub_region: 'MD_CENTRAL',
            sub_region_id: 'MD1',
          },
          {
            sub_region: 'MD_REST_ALL',
            sub_region_id: 'MD2',
          },
        ],
      },
      {
        state: 'ME',
        sub_regions: [
          {
            sub_region: 'ME_EAST',
            sub_region_id: 'ME1',
          },
          {
            sub_region: 'ME_WEST',
            sub_region_id: 'ME2',
          },
        ],
      },
      {
        state: 'NY',
        sub_regions: [
          {
            sub_region: 'NY_BROOKLYN',
            sub_region_id: 'NY1',
          },
          {
            sub_region: 'NY_CENTRAL',
            sub_region_id: 'NY2',
          },
          {
            sub_region: 'NY_NORTH_CENTRAL',
            sub_region_id: 'NY3',
          },
          {
            sub_region: 'NY_NORTH_WEST',
            sub_region_id: 'NY4',
          },
          {
            sub_region: 'NY_SOUTH',
            sub_region_id: 'NY5',
          },
        ],
      },
      {
        state: 'PA',
        sub_regions: [
          {
            sub_region: 'PA_CENTRAL',
            sub_region_id: 'PA1',
          },
          {
            sub_region: 'PA_CENTRAL_NORTH',
            sub_region_id: 'PA2',
          },
          {
            sub_region: 'PA_NORTH_EAST',
            sub_region_id: 'PA3',
          },
          {
            sub_region: 'PA_SOUTH',
            sub_region_id: 'PA4',
          },
          {
            sub_region: 'PA_WEST',
            sub_region_id: 'PA5',
          },
        ],
      },
      {
        state: 'VT',
        sub_regions: [
          {
            sub_region: 'VT',
            sub_region_id: 'VT',
          },
        ],
      },
    ],
  },
  {
    region: 'West Region',
    states: [
      {
        state: 'NM',
        sub_regions: [
          {
            sub_region: 'NM_CENTRAL',
            sub_region_id: 'NM1',
          },
          {
            sub_region: 'NM_REST_ALL',
            sub_region_id: 'NM2',
          },
        ],
      },
      {
        state: 'NV',
        sub_regions: [
          {
            sub_region: 'NV_LAS_VEGAS',
            sub_region_id: 'NV1',
          },
          {
            sub_region: 'NV_NORTH',
            sub_region_id: 'NV2',
          },
          {
            sub_region: 'NV_SOUTH',
            sub_region_id: 'NV3',
          },
        ],
      },
      {
        state: 'WA',
        sub_regions: [
          {
            sub_region: 'WA',
            sub_region_id: 'WA',
          },
        ],
      },
    ],
  },
  {
    region: 'South Region',
    states: [
      {
        state: 'NC',
        sub_regions: [
          {
            sub_region: 'NC_EAST',
            sub_region_id: 'NC1',
          },
          {
            sub_region: 'NC_NORTH',
            sub_region_id: 'NC2',
          },
          {
            sub_region: 'NC_SOUTH',
            sub_region_id: 'NC3',
          },
          {
            sub_region: 'NC_WEST',
            sub_region_id: 'NC4',
          },
        ],
      },
      {
        state: 'OK',
        sub_regions: [
          {
            sub_region: 'OK_NORTH_EAST',
            sub_region_id: 'OK1',
          },
          {
            sub_region: 'OK_REST_ALL',
            sub_region_id: 'OK2',
          },
        ],
      },
      {
        state: 'SC',
        sub_regions: [
          {
            sub_region: 'SC_CENTRAL',
            sub_region_id: 'SC1',
          },
          {
            sub_region: 'SC_NORTH',
            sub_region_id: 'SC2',
          },
          {
            sub_region: 'SC_SOUTH',
            sub_region_id: 'SC3',
          },
        ],
      },
      {
        state: 'TN',
        sub_regions: [
          {
            sub_region: 'TN_CENTRAL',
            sub_region_id: 'TN1',
          },
          {
            sub_region: 'TN_EAST',
            sub_region_id: 'TN2',
          },
          {
            sub_region: 'TN_WEST',
            sub_region_id: 'TN3',
          },
        ],
      },
      {
        state: 'TX',
        sub_regions: [
          {
            sub_region: 'TX_CENTRAL',
            sub_region_id: 'TX1',
          },
          {
            sub_region: 'TX_CENTRAL_NORTH',
            sub_region_id: 'TX2',
          },
          {
            sub_region: 'TX_CENTRAL_WEST',
            sub_region_id: 'TX3',
          },
          {
            sub_region: 'TX_DALLAS',
            sub_region_id: 'TX4',
          },
          {
            sub_region: 'TX_HOUSTON',
            sub_region_id: 'TX5',
          },
          {
            sub_region: 'TX_NORTH_EAST',
            sub_region_id: 'TX6',
          },
          {
            sub_region: 'TX_NORTH_WEST',
            sub_region_id: 'TX7',
          },
          {
            sub_region: 'TX_SOUTH',
            sub_region_id: 'TX8',
          },
          {
            sub_region: 'TX_SOUTH_EAST',
            sub_region_id: 'TX9',
          },
          {
            sub_region: 'TX_SOUTH_WEST',
            sub_region_id: 'TX10',
          },
        ],
      },
      {
        state: 'VA',
        sub_regions: [
          {
            sub_region: 'VA_CENTRAL',
            sub_region_id: 'VA1',
          },
          {
            sub_region: 'VA_NORTH',
            sub_region_id: 'VA2',
          },
          {
            sub_region: 'VA_SOUTH_EAST',
            sub_region_id: 'VA3',
          },
          {
            sub_region: 'VA_SOUTH_WEST',
            sub_region_id: 'VA4',
          },
        ],
      },
      {
        state: 'WV',
        sub_regions: [
          {
            sub_region: 'WV_EAST',
            sub_region_id: 'WV1',
          },
          {
            sub_region: 'WV_WEST',
            sub_region_id: 'WV2',
          },
        ],
      },
    ],
  },
  {
    region: 'West Region States',
    states: [
      {
        state: 'AZ',
        sub_regions: [
          {
            sub_region: 'AZ_NORTH',
            sub_region_id: 'AZ1',
          },
          {
            sub_region: 'AZ_PHOENIX',
            sub_region_id: 'AZ2',
          },
          {
            sub_region: 'AZ_SOUTH',
            sub_region_id: 'AZ3',
          },
        ],
      },
      {
        state: 'CA',
        sub_regions: [
          {
            sub_region: 'CA_CENTRAL',
            sub_region_id: 'CA1',
          },
          {
            sub_region: 'CA_CENTRAL_NORTH',
            sub_region_id: 'CA2',
          },
          {
            sub_region: 'CA_CENTRAL_SOUTH',
            sub_region_id: 'CA3',
          },
          {
            sub_region: 'CA_CENTRAL_WEST',
            sub_region_id: 'CA4',
          },
          {
            sub_region: 'CA_LONG_BEACH',
            sub_region_id: 'CA5',
          },
          {
            sub_region: 'CA_LOS_ANGELES',
            sub_region_id: 'CA6',
          },
          {
            sub_region: 'CA_LOS_ANGELES_VENTURA_COUNTY',
            sub_region_id: 'CA7',
          },
          {
            sub_region: 'CA_NORTH',
            sub_region_id: 'CA8',
          },
          {
            sub_region: 'CA_ONTARIO',
            sub_region_id: 'CA9',
          },
          {
            sub_region: 'CA_RIVERSIDE_IMPERIAL',
            sub_region_id: 'CA10',
          },
          {
            sub_region: 'CA_SAN_BERNARDINO',
            sub_region_id: 'CA11',
          },
          {
            sub_region: 'CA_SAN_DIEGO',
            sub_region_id: 'CA12',
          },
          {
            sub_region: 'CA_SAN_DIEGO_COUNTY',
            sub_region_id: 'CA13',
          },
          {
            sub_region: 'CA_SAN_FRANCISCO',
            sub_region_id: 'CA14',
          },
          {
            sub_region: 'CA_SANTA_ANA',
            sub_region_id: 'CA15',
          },
        ],
      },
      {
        state: 'ID',
        sub_regions: [
          {
            sub_region: 'ID_BOISE_AND_NAMPA',
            sub_region_id: 'ID1',
          },
          {
            sub_region: 'ID_NORTH_AND_EAST',
            sub_region_id: 'ID2',
          },
        ],
      },
      {
        state: 'MT',
        sub_regions: [
          {
            sub_region: 'MT',
            sub_region_id: 'MT',
          },
        ],
      },
      {
        state: 'NV',
        sub_regions: [
          {
            sub_region: 'NV_LAS_VEGAS',
            sub_region_id: 'NV1',
          },
          {
            sub_region: 'NV_NORTH',
            sub_region_id: 'NV2',
          },
          {
            sub_region: 'NV_SOUTH',
            sub_region_id: 'NV3',
          },
        ],
      },
      {
        state: 'OR',
        sub_regions: [
          {
            sub_region: 'OR_CENTRAL',
            sub_region_id: 'OR1',
          },
          {
            sub_region: 'OR_NORTH_WEST',
            sub_region_id: 'OR2',
          },
        ],
      },
      {
        state: 'UT',
        sub_regions: [
          {
            sub_region: 'UT_NORTH',
            sub_region_id: 'UT1',
          },
          {
            sub_region: 'UT_SOUTH',
            sub_region_id: 'UT2',
          },
        ],
      },
      {
        state: 'WA',
        sub_regions: [
          {
            sub_region: 'WA',
            sub_region_id: 'WA',
          },
        ],
      },
      {
        state: 'WY',
        sub_regions: [
          {
            sub_region: 'WY',
            sub_region_id: 'WY',
          },
        ],
      },
    ],
  },
]
