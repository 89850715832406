<template>
  <div class="">
    <dx-accordion :data-source="regions"
      :collapsible="true"
      :multiple="true"
      :animation-duration="450"
      item-template="item"
      item-title-template="title"
    >
      <template #title="{ data }">
        {{ data.region }}
      </template>
      <template #item="{ data }">
        <div>
          <div v-for="state in data.states" :key="state.state" class="d-flex align-items-center justify-content-start px-1">
            <div class="mr-2 bg-primary badge p-1" style="width: 80px;">
              {{ state.state }}
            </div>
            <div v-for="subregion in state.sub_regions" :key="subregion.sub_region_id" class="d-flex align-items-center justify-content-start">
              <div class="m-half p-1 badge" :class="isSubRegionInFiltered(subregion.sub_region_id) ? 'bg-success' : 'bg-warning'" style="width: 80px;">
                {{ subregion.sub_region_id }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </dx-accordion>
  </div>
</template>

<script>
import { DxAccordion } from 'devextreme-vue/accordion'
import { regions } from './region-list'

export default {
  components: {
    DxAccordion,
  },
  props: {
    regionData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      regions,
    }
  },
  computed: {
    filteredStateRegions() {
      const stateSubRegions = []
      const data = this.regionData.filter(el => el.regionCode === 'C')[0].subRegions
      data.forEach(subregion => {
        subregion.states.forEach(state => {
          state.stateSubregions.forEach(stateSubregion => {
            const randomValue = Math.random()
            const probabilityThreshold = 0.5
            if (randomValue < probabilityThreshold) {
              stateSubRegions.push(stateSubregion.stateSubregionCode)
            }
          })
        })
      })
      return stateSubRegions
    },
  },
  methods: {
    isSubRegionInFiltered(subRegionId) {
      return this.filteredStateRegions.includes(subRegionId)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
